.vt-filter .mat-form-field {
  width: 300px;
  margin-right: 10px;
}

.vt-filter .mat-spinner {
  position: relative;
  top: 10px;
  left: 10px;
  visibility: hidden;
}

.vt-filter .mat-spinner.show {
  visibility: visible;
}
