.default-theme {
  .mat-expansion-panel {
    margin-bottom: 10px;
    border-radius: 0;
    box-shadow: 1px 2px 1px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid #f1f1f1;

    .mat-expansion-panel-header {
      background-color: $whiteSmoke;
      &.mat-expanded {
        background-color: $whiteSmoke;
        margin-bottom: 25px;
      }
    }

    .mat-column-actions {
      padding-right: 0;
    }
  }

  .mat-accordion {
    .mat-expansion-panel {
      &:first-of-type,
      &:last-of-type {
        border-radius: 0;
      }
      &:last-of-type {
        margin-bottom: 10px;
      }
    }

    > .mat-expansion-panel-spacing:last-child,
    .mat-accordion > :last-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
      margin-bottom: 10px;
    }
  }
}
