@import 'colors.scss';

.mat-card {
  &.wizard-holder {
    box-shadow: none;
    padding-bottom: 300px; // 120px
    border-top: 1px solid rgba($black, 0.15);
  }
}

.wizard {
  display: flex;
  align-items: flex-start;

  .mat-stepper-horizontal {
    flex: 1 1 auto;
    max-width: 100%;

    .mat-stepper-horizontal {
      .mat-stepper-horizontal-line {
        display: none;
      }
    }
  }

  .mat-stepper-vertical {
    flex: 1 1 auto;
    max-width: 100%;
  }

  .wizard__preview {
    width: 100%;
    height: 280px; // 140px
    padding: 20px;
    background-color: $white;
    border-top: 1px solid rgba($black, 0.15);
    overflow: auto;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9;

    .wizard__preview-text {
      display: flex;
      align-items: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      span {
        font-size: 32px;
        opacity: 0.15;
      }

      .spinner {
        margin-right: 10px;
      }
    }

    .wizard__preview-image {
      height: 100%;
      text-align: center;
      cursor: pointer;

      img {
        height: 100%;
        width: auto;
        max-width: none;
      }
    }
  }
}
