@import 'colors.scss';

.mat-header-row {
    background-color: $darkGreen;
}

.mat-header-cell , .mat-sort-header-arrow, .mat-header-cell button {
    color:white;
    font-size: 14px;
}

.mat-header-cell button {
    margin-right: 10px;
}

.mat-table {
    margin-top: 20px;
}