/* You can add global styles to this file, and also import other style files */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-badge-small .mat-badge-content {
  font-size: 6px; }

.mat-badge-large .mat-badge-content {
  font-size: 24px; }

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h5, .mat-typography h5 {
  font: 400 11.62px/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px; }

.mat-h6, .mat-typography h6 {
  font: 400 9.38px/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px; }

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif; }

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif; }
  .mat-body p, .mat-body-1 p, .mat-typography p {
    margin: 0 0 12px; }

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif; }

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 56px;
  letter-spacing: -0.05em; }

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px;
  letter-spacing: -0.02em; }

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px;
  letter-spacing: -0.005em; }

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px; }

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif; }

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-card-title {
  font-size: 24px;
  font-weight: 500; }

.mat-card-header .mat-card-title {
  font-size: 20px; }

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px; }

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px; }

.mat-chip {
  font-size: 14px;
  font-weight: 500; }
  .mat-chip .mat-chip-trailing-icon.mat-icon,
  .mat-chip .mat-chip-remove.mat-icon {
    font-size: 18px; }

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-header-cell {
  font-size: 12px;
  font-weight: 500; }

.mat-cell, .mat-footer-cell {
  font-size: 14px; }

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-calendar-body {
  font-size: 13px; }

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500; }

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400; }

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif; }

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400; }

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif; }

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-form-field-wrapper {
  padding-bottom: 1.34375em; }

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125; }

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em; }
  .mat-form-field-prefix .mat-icon-button .mat-icon,
  .mat-form-field-suffix .mat-icon-button .mat-icon {
    height: 1.125em;
    line-height: 1.125; }

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent; }

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em; }

.mat-form-field-label {
  top: 1.34375em; }

.mat-form-field-underline {
  bottom: 1.34375em; }

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.66666667em;
  top: calc(100% - 1.79166667em); }

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0; }

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.33335333%; }

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em; }

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.54166667em;
  top: calc(100% - 1.66666667em); }

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75); }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper
.mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75); }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75); } }

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0; }

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em; }

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0; }

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em; }

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px; }
  .mat-grid-tile-header .mat-line,
  .mat-grid-tile-footer .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-grid-tile-header .mat-line:nth-child(n+2),
    .mat-grid-tile-footer .mat-line:nth-child(n+2) {
      font-size: 12px; }

input.mat-input-element {
  margin-top: -0.0625em; }

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400; }

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px; }

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-select-trigger {
  height: 1.125em; }

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500; }

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-step-label {
  font-size: 14px;
  font-weight: 400; }

.mat-step-sub-label-error {
  font-weight: normal; }

.mat-step-label-error {
  font-size: 14px; }

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500; }

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0; }

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px; }

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px; }

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-list-base .mat-list-item {
  font-size: 16px; }
  .mat-list-base .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list-base .mat-list-option {
  font-size: 16px; }
  .mat-list-base .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.mat-list-base[dense] .mat-list-item {
  font-size: 12px; }
  .mat-list-base[dense] .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list-base[dense] .mat-list-option {
  font-size: 12px; }
  .mat-list-base[dense] .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500; }

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px; }

.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif; }

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px; }

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500; }

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px; }

.mat-ripple {
  overflow: hidden;
  position: relative; }

.mat-ripple.mat-ripple-unbounded {
  overflow: visible; }

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0); }
  @media (-ms-high-contrast: active) {
    .mat-ripple-element {
      display: none; } }

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none; }

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.cdk-overlay-container {
  position: fixed;
  z-index: 1000; }
  .cdk-overlay-container:empty {
    display: none; }

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000; }

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%; }

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0; }
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1; }
    @media screen and (-ms-high-contrast: active) {
      .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
        opacity: 0.6; } }

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32); }

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0; }

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px; }

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll; }

@keyframes cdk-text-field-autofill-start {
  /*!*/ }

@keyframes cdk-text-field-autofill-end {
  /*!*/ }

.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation-name: cdk-text-field-autofill-start; }

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation-name: cdk-text-field-autofill-end; }

textarea.cdk-textarea-autosize {
  resize: none; }

textarea.cdk-textarea-autosize-measuring {
  height: auto !important;
  overflow: hidden !important;
  padding: 2px 0 !important;
  box-sizing: content-box !important; }

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1); }

.mat-option {
  color: rgba(0, 0, 0, 0.87); }
  .mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04); }
  .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04); }
  .mat-option.mat-active {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87); }
  .mat-option.mat-option-disabled {
    color: rgba(0, 0, 0, 0.38); }

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #757575; }

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #50af64; }

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #d32f2f; }

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54); }

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38); }

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54); }
  .mat-pseudo-checkbox::after {
    color: #fafafa; }

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0; }

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #50af64; }

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #757575; }

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #d32f2f; }

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0; }

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87); }

.mat-theme-loaded-marker {
  display: none; }

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-autocomplete-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
    background: white; }
    .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
      color: rgba(0, 0, 0, 0.87); }

.mat-badge-content {
  color: white;
  background: #757575; }
  @media (-ms-high-contrast: active) {
    .mat-badge-content {
      outline: solid 1px;
      border-radius: 0; } }

.mat-badge-accent .mat-badge-content {
  background: #50af64;
  color: white; }

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #d32f2f; }

.mat-badge {
  position: relative; }

.mat-badge-hidden .mat-badge-content {
  display: none; }

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38); }

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none; }

.mat-badge-content.mat-badge-active {
  transform: none; }

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px; }

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px; }

.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px; }

.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px; }

[dir='rtl'] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px; }

.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px; }

[dir='rtl'] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px; }

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px; }

[dir='rtl'] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px; }

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px; }

[dir='rtl'] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px; }

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px; }

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px; }

.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px; }

.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px; }

[dir='rtl'] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px; }

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px; }

[dir='rtl'] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px; }

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px; }

[dir='rtl'] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px; }

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px; }

[dir='rtl'] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px; }

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px; }

.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px; }

.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px; }

.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px; }

[dir='rtl'] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px; }

.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px; }

[dir='rtl'] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px; }

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px; }

[dir='rtl'] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px; }

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px; }

[dir='rtl'] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px; }

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent; }
  .mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
    color: #757575; }
  .mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
    color: #50af64; }
  .mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
    color: #d32f2f; }
  .mat-button.mat-primary[disabled], .mat-button.mat-accent[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-primary[disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26); }
  .mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
    background-color: #757575; }
  .mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
    background-color: #50af64; }
  .mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
    background-color: #d32f2f; }
  .mat-button[disabled] .mat-button-focus-overlay, .mat-icon-button[disabled] .mat-button-focus-overlay, .mat-stroked-button[disabled] .mat-button-focus-overlay {
    background-color: transparent; }
  .mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
    opacity: 0.1;
    background-color: currentColor; }

.mat-button-focus-overlay {
  background: black; }

.mat-stroked-button:not([disabled]) {
  border-color: rgba(0, 0, 0, 0.12); }

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white; }
  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    color: white; }
  .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
    color: white; }
  .mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
    color: white; }
  .mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26); }
  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    background-color: #757575; }
  .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
    background-color: #50af64; }
  .mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
    background-color: #d32f2f; }
  .mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
    background-color: rgba(0, 0, 0, 0.12); }
  .mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }
  .mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }
  .mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }

.mat-stroked-button:not([class*='mat-elevation-z']), .mat-flat-button:not([class*='mat-elevation-z']) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-raised-button:not([class*='mat-elevation-z']) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-raised-button:not([disabled]):active:not([class*='mat-elevation-z']) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.mat-raised-button[disabled]:not([class*='mat-elevation-z']) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-fab:not([class*='mat-elevation-z']), .mat-mini-fab:not([class*='mat-elevation-z']) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-fab:not([disabled]):active:not([class*='mat-elevation-z']), .mat-mini-fab:not([disabled]):active:not([class*='mat-elevation-z']) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.mat-fab[disabled]:not([class*='mat-elevation-z']), .mat-mini-fab[disabled]:not([class*='mat-elevation-z']) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none; }

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38); }
  .mat-button-toggle .mat-button-toggle-focus-overlay {
    background-color: rgba(0, 0, 0, 0.12); }

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white; }
  .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
    background-color: black; }

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12); }

[dir='rtl'] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12); }

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12); }

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54); }
  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: rgba(0, 0, 0, 0.87); }

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee; }
  .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
    background: white; }
  .mat-button-toggle-disabled.mat-button-toggle-checked {
    background-color: #bdbdbd; }

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12); }

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-card:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }
  .mat-card.mat-card-flat:not([class*='mat-elevation-z']) {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54); }

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54); }

.mat-checkbox-checkmark {
  fill: #fafafa; }

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important; }
  @media (-ms-high-contrast: black-on-white) {
    .mat-checkbox-checkmark-path {
      stroke: #000 !important; } }

.mat-checkbox-mixedmark {
  background-color: #fafafa; }

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #757575; }

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #50af64; }

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #d32f2f; }

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0; }

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0; }

.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54); }

@media (-ms-high-contrast: active) {
  .mat-checkbox-disabled {
    opacity: 0.5; } }

@media (-ms-high-contrast: active) {
  .mat-checkbox-background {
    background: none; } }

.mat-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mat-ripple-element {
  background-color: #757575; }

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: #50af64; }

.mat-checkbox:not(.mat-checkbox-disabled).mat-warn .mat-checkbox-ripple .mat-ripple-element {
  background-color: #d32f2f; }

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87); }
  .mat-chip.mat-standard-chip .mat-chip-remove {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.4; }
  .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }
  .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
    opacity: 0.54; }
  .mat-chip.mat-standard-chip.mat-chip-disabled {
    opacity: 0.4; }
  .mat-chip.mat-standard-chip::after {
    background: black; }

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #757575;
  color: white; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
    background: rgba(255, 255, 255, 0.1); }

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #d32f2f;
  color: white; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
    background: rgba(255, 255, 255, 0.1); }

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #50af64;
  color: white; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
    background: rgba(255, 255, 255, 0.1); }

.mat-table {
  background: white; }

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit; }

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12); }

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54); }

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87); }

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54); }

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54); }

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38); }

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12); }

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54); }

.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent; }

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0, 0, 0, 0.38); }

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(0, 0, 0, 0.04); }

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.18); }

.mat-calendar-body-selected {
  background-color: #757575;
  color: white; }

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(117, 117, 117, 0.4); }

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white; }

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
    background-color: #50af64;
    color: white; }
  .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(80, 175, 100, 0.4); }
  .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white; }
  .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
    background-color: #d32f2f;
    color: white; }
  .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(211, 47, 47, 0.4); }
  .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white; }

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-datepicker-toggle-active {
  color: #757575; }
  .mat-datepicker-toggle-active.mat-accent {
    color: #50af64; }
  .mat-datepicker-toggle-active.mat-warn {
    color: #d32f2f; }

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12); }

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12); }

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12); }

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-keyboard-focused, .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-program-focused, .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
  background: rgba(0, 0, 0, 0.04); }

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true'])
.mat-expansion-panel-header:hover {
    background: white; } }

.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87); }

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54); }

.mat-expansion-panel-header[aria-disabled='true'] {
  color: rgba(0, 0, 0, 0.26); }
  .mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-title,
  .mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-description {
    color: inherit; }

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6); }

.mat-hint {
  color: rgba(0, 0, 0, 0.6); }

.mat-form-field.mat-focused .mat-form-field-label {
  color: #757575; }
  .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
    color: #50af64; }
  .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
    color: #d32f2f; }

.mat-focused .mat-form-field-required-marker {
  color: #50af64; }

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87); }

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #757575; }
  .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
    background-color: #50af64; }
  .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
    background-color: #d32f2f; }

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #757575; }

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #50af64; }

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #d32f2f; }

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #d32f2f; }
  .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
  .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
    color: #d32f2f; }

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #d32f2f; }

.mat-error {
  color: #d32f2f; }

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54); }

.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54); }

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42); }

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x; }

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42); }

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x; }

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04); }

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02); }

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42); }

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38); }

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent; }

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12); }

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87); }

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #757575; }

.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #50af64; }

.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #d32f2f; }

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #d32f2f; }

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38); }

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06); }

.mat-icon.mat-primary {
  color: #757575; }

.mat-icon.mat-accent {
  color: #50af64; }

.mat-icon.mat-warn {
  color: #d32f2f; }

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54); }

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38); }

.mat-input-element {
  caret-color: #757575; }
  .mat-input-element::placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .mat-input-element::-moz-placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .mat-input-element::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .mat-input-element:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.42); }

.mat-accent .mat-input-element {
  caret-color: #50af64; }

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #d32f2f; }

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #d32f2f; }

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87); }

.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87); }

.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54); }

.mat-list-item-disabled {
  background-color: #eeeeee; }

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04); }

.mat-menu-panel {
  background: white; }
  .mat-menu-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87); }
  .mat-menu-item[disabled], .mat-menu-item[disabled]::after {
    color: rgba(0, 0, 0, 0.38); }

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54); }

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04); }

.mat-paginator {
  background: white; }

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54); }

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54); }

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54); }

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-progress-bar-background {
  fill: whitesmoke; }

.mat-progress-bar-buffer {
  background-color: whitesmoke; }

.mat-progress-bar-fill::after {
  background-color: #757575; }

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #50af64; }

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #50af64; }

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #50af64; }

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2; }

.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2; }

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #d32f2f; }

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #757575; }

.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #50af64; }

.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #d32f2f; }

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54); }

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #757575; }

.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #757575; }

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #50af64; }

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #50af64; }

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #d32f2f; }

.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #d32f2f; }

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38); }

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38); }

.mat-radio-button .mat-ripple-element {
  background-color: black; }

.mat-select-value {
  color: rgba(0, 0, 0, 0.87); }

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42); }

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38); }

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54); }

.mat-select-panel {
  background: white; }
  .mat-select-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background: rgba(0, 0, 0, 0.12); }

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #757575; }

.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #50af64; }

.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #d32f2f; }

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #d32f2f; }

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38); }

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87); }

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-drawer.mat-drawer-push {
    background-color: white; }
  .mat-drawer:not(.mat-drawer-side) {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12); }
  .mat-drawer-side.mat-drawer-end {
    border-left: solid 1px rgba(0, 0, 0, 0.12);
    border-right: none; }

[dir='rtl'] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none; }
  [dir='rtl'] .mat-drawer-side.mat-drawer-end {
    border-left: none;
    border-right: solid 1px rgba(0, 0, 0, 0.12); }

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6); }

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #50af64; }

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(80, 175, 100, 0.54); }

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #50af64; }

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #757575; }

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(117, 117, 117, 0.54); }

.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #757575; }

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #d32f2f; }

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(211, 47, 47, 0.54); }

.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #d32f2f; }

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black; }

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa; }

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38); }

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #757575; }

.mat-primary .mat-slider-thumb-label-text {
  color: white; }

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #50af64; }

.mat-accent .mat-slider-thumb-label-text {
  color: white; }

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #d32f2f; }

.mat-warn .mat-slider-thumb-label-text {
  color: white; }

.mat-slider-focus-ring {
  background-color: rgba(80, 175, 100, 0.2); }

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38); }

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12); }

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87); }

.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent; }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26); }

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7); }

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent); }

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent); }

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04); }

@media (hover: none) {
  .mat-step-header:hover {
    background: none; } }

.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54); }

.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white; }

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #757575;
  color: white; }

.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #d32f2f; }

.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87); }

.mat-step-header .mat-step-label.mat-step-label-error {
  color: #d32f2f; }

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white; }

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12); }

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12); }

.mat-sort-header-arrow {
  color: #757575; }

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none; }

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87); }
  .mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
    color: rgba(0, 0, 0, 0.38); }

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87); }

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-tab-group[class*='mat-background-'] .mat-tab-header,
.mat-tab-nav-bar[class*='mat-background-'] {
  border-bottom: none;
  border-top: none; }

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(245, 245, 245, 0.3); }

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #757575; }

.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white; }

.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(80, 175, 100, 0.3); }

.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #50af64; }

.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white; }

.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3); }

.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #d32f2f; }

.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white; }

.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(245, 245, 245, 0.3); }

.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links {
  background-color: #757575; }

.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white; }
  .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white; }

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(80, 175, 100, 0.3); }

.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links {
  background-color: #50af64; }

.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white; }
  .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white; }

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3); }

.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links {
  background-color: #d32f2f; }

.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white; }
  .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white; }

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87); }
  .mat-toolbar.mat-primary {
    background: #757575;
    color: white; }
  .mat-toolbar.mat-accent {
    background: #50af64;
    color: white; }
  .mat-toolbar.mat-warn {
    background: #d32f2f;
    color: white; }
  .mat-toolbar .mat-form-field-underline,
  .mat-toolbar .mat-form-field-ripple,
  .mat-toolbar .mat-focused .mat-form-field-ripple {
    background-color: currentColor; }
  .mat-toolbar .mat-form-field-label,
  .mat-toolbar .mat-focused .mat-form-field-label,
  .mat-toolbar .mat-select-value,
  .mat-toolbar .mat-select-arrow,
  .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
    color: inherit; }
  .mat-toolbar .mat-input-element {
    caret-color: currentColor; }

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9); }

.mat-tree {
  background: white; }

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87); }

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-simple-snackbar-action {
  color: #50af64; }

button {
  border-radius: 0; }

.grid-header .mat-grid-tile .mat-figure {
  justify-content: flex-start; }

.grid-paginator .mat-paginator-container {
  justify-content: center; }

.mat-grid-tile.vt-grid-figure .mat-figure {
  align-items: normal;
  justify-content: normal;
  display: block; }

.vt-grid-figure > div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: min-content; }

.vt-grid-figure mat-form-field {
  width: 50%; }

.vt-grid-figure label {
  width: 50%; }

.vt-max-no-scroll {
  height: calc(100vh - 205px); }

.vt-tab-group {
  overflow: hidden; }
  .vt-tab-group .mat-tab-header {
    background-color: #fff; }
  .vt-tab-group .vt-tab-title {
    color: #333; }
  .vt-tab-group .vt-tab-form {
    display: flex; }
  .vt-tab-group .vt-textarea {
    width: 50%; }

.vt-tab-card {
  border-radius: 0; }

.mat-tab-label-container {
  background-color: #005555; }

.mat-tab-label {
  opacity: 1;
  color: white; }

.mat-tab-label-active {
  color: white;
  opacity: 1 !important; }

.mat-ink-bar {
  color: white;
  border-bottom: 1px solid #f5f5f5; }

.vt-filter .mat-form-field {
  width: 300px;
  margin-right: 10px; }

.vt-filter .mat-spinner {
  position: relative;
  top: 10px;
  left: 10px;
  visibility: hidden; }

.vt-filter .mat-spinner.show {
  visibility: visible; }

.mat-dialog-content .vt-form-label {
  width: 40%;
  display: inline-block; }

.mat-dialog-content mat-form-field.full-width {
  width: 60%; }

.mat-dialog-content mat-form-field.small {
  width: 20%; }

.mat-dialog-content mat-form-field.medium {
  width: 40%; }

.mat-dialog-content mat-form-field.large {
  width: 50%; }

.default-theme .mat-expansion-panel {
  margin-bottom: 10px;
  border-radius: 0;
  box-shadow: 1px 2px 1px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #f1f1f1; }
  .default-theme .mat-expansion-panel .mat-expansion-panel-header {
    background-color: #f5f5f5; }
    .default-theme .mat-expansion-panel .mat-expansion-panel-header.mat-expanded {
      background-color: #f5f5f5;
      margin-bottom: 25px; }
  .default-theme .mat-expansion-panel .mat-column-actions {
    padding-right: 0; }

.default-theme .mat-accordion .mat-expansion-panel:first-of-type, .default-theme .mat-accordion .mat-expansion-panel:last-of-type {
  border-radius: 0; }

.default-theme .mat-accordion .mat-expansion-panel:last-of-type {
  margin-bottom: 10px; }

.default-theme .mat-accordion > .mat-expansion-panel-spacing:last-child,
.default-theme .mat-accordion .mat-accordion > :last-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
  margin-bottom: 10px; }

.mat-card.wizard-holder {
  box-shadow: none;
  padding-bottom: 300px;
  border-top: 1px solid rgba(51, 51, 51, 0.15); }

.wizard {
  display: flex;
  align-items: flex-start; }
  .wizard .mat-stepper-horizontal {
    flex: 1 1 auto;
    max-width: 100%; }
    .wizard .mat-stepper-horizontal .mat-stepper-horizontal .mat-stepper-horizontal-line {
      display: none; }
  .wizard .mat-stepper-vertical {
    flex: 1 1 auto;
    max-width: 100%; }
  .wizard .wizard__preview {
    width: 100%;
    height: 280px;
    padding: 20px;
    background-color: #fff;
    border-top: 1px solid rgba(51, 51, 51, 0.15);
    overflow: auto;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9; }
    .wizard .wizard__preview .wizard__preview-text {
      display: flex;
      align-items: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
      .wizard .wizard__preview .wizard__preview-text span {
        font-size: 32px;
        opacity: 0.15; }
      .wizard .wizard__preview .wizard__preview-text .spinner {
        margin-right: 10px; }
    .wizard .wizard__preview .wizard__preview-image {
      height: 100%;
      text-align: center;
      cursor: pointer; }
      .wizard .wizard__preview .wizard__preview-image img {
        height: 100%;
        width: auto;
        max-width: none; }

.flex-row {
  display: flex; }

.flex-1 {
  flex: 0 0 calc((1/12)*100%); }

.flex-2 {
  flex: 0 0 calc((2/12)*100%); }

.flex-3 {
  flex: 0 0 calc((3/12)*100%); }

.flex-4 {
  flex: 0 0 calc((4/12)*100%); }

.flex-5 {
  flex: 0 0 calc((5/12)*100%); }

.flex-6 {
  flex: 0 0 calc((6/12)*100%); }

.flex-7 {
  flex: 0 0 calc((7/12)*100%); }

.flex-8 {
  flex: 0 0 calc((8/12)*100%); }

.flex-9 {
  flex: 0 0 calc((9/12)*100%); }

.flex-10 {
  flex: 0 0 calc((10/12)*100%); }

.flex-11 {
  flex: 0 0 calc((11/12)*100%); }

.flex-12 {
  flex: 0 0 calc((12/12)*100%); }

.fill-space {
  flex: 1 1 auto;
  width: 100%; }

.flex-column {
  flex-direction: column; }

.mat-header-row {
  background-color: #005555; }

.mat-header-cell, .mat-sort-header-arrow, .mat-header-cell button {
  color: white;
  font-size: 14px; }

.mat-header-cell button {
  margin-right: 10px; }

.mat-table {
  margin-top: 20px; }

* {
  box-sizing: border-box;
  font-family: Roboto, 'Helvetica Neue', sans-serif; }

html {
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: none;
  overflow: auto; }

img {
  max-width: 100%; }

body .mat-tab-body-content {
  overflow: hidden; }

body .mat-tab-body-content, .mat-card {
  color: #283754; }

.mat-cell, .mat-footer-cell {
  color: #283754; }

.mat-progress-spinner svg {
  width: 30px;
  height: 30px;
  padding: 5px; }

.mat-spinner-container {
  position: relative; }

.mat-spinner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.mat-progress-spinner.mat-spinner-background {
  position: absolute;
  top: 0;
  left: 0; }

.margin-tab-group mat-tab-header {
  margin-bottom: 10px; }

textarea {
  vertical-align: top !important;
  resize: none !important; }

.mat-card-actions {
  padding: 0 !important;
  margin-bottom: 0 !important; }

snack-bar-container > simple-snack-bar.mat-simple-snackbar > div.mat-simple-snackbar-action > button.mat-button {
  line-height: 0;
  padding: 0; }

.error-snack-bar {
  background-color: #F09100; }
  .error-snack-bar .mat-simple-snackbar-action,
  .error-snack-bar .mat-simple-snackbar {
    color: #fff; }

.success-snack-bar {
  background-color: #50AF64; }
  .success-snack-bar .mat-simple-snackbar-action,
  .success-snack-bar .mat-simple-snackbar {
    color: #fff; }

.info-snack-bar {
  background-color: #369ef4; }
  .info-snack-bar .mat-simple-snackbar-action,
  .info-snack-bar .mat-simple-snackbar {
    color: #fff; }

.full-width {
  width: 100vw; }

.pointer {
  cursor: pointer; }

.zoom-in {
  cursor: zoom-in; }

mat-error:not(:first-of-type) {
  display: none; }

button[disabled], button[disabled][disabled], button:disabled {
  background: transparent;
  opacity: 1;
  color: #c2c1c1; }
  button[disabled] .material-icons, button[disabled][disabled] .material-icons, button:disabled .material-icons {
    color: #c2c1c1; }
  button[disabled]:hover, button[disabled][disabled]:hover, button:disabled:hover {
    color: #c2c1c1; }
    button[disabled]:hover .material-icons, button[disabled][disabled]:hover .material-icons, button:disabled:hover .material-icons {
      color: #c2c1c1; }

button.red:hover {
  color: #F09100; }
  button.red:hover .material-icons {
    color: #F09100; }

button.green .material-icons {
  color: #50AF64; }

button.green:hover {
  color: #50AF64; }

button.blue:hover {
  color: #369ef4; }
  button.blue:hover .material-icons {
    color: #369ef4; }

button.orange:hover {
  color: #F09100; }
  button.orange:hover .material-icons {
    color: #F09100; }

.default-theme .mat-action-row {
  padding: 10px 8px 10px 24px; }

.detail-row {
  display: flex;
  margin-left: -16px;
  margin-right: -16px; }
  .detail-row .detail-col {
    width: 50%;
    padding-left: 16px;
    padding-right: 16px; }
    .detail-row .detail-col .detail-col-center {
      top: 50%;
      left: 50%;
      position: relative;
      transform: translate(-50%, -50%); }
      .detail-row .detail-col .detail-col-center .detail-col-center-row {
        padding-top: 5px;
        padding-bottom: 5px; }

.mat-form-field-label-wrapper {
  display: none; }

.block-filter .mat-form-field-label-wrapper,
.login-card .mat-form-field-label-wrapper {
  display: block; }

.mat-drawer-content {
  background-color: #fff; }

.mat-card.block-header {
  display: flex;
  align-items: flex-start;
  box-shadow: none; }
  .mat-card.block-header .mat-card-title {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column; }
  .mat-card.block-header .block-header__subtitle {
    display: block;
    color: #757575;
    font-size: 12px;
    margin-top: 3px;
    margin-bottom: 0;
    margin-left: 5px; }
  .mat-card.block-header .block-header__actions {
    margin-left: auto; }

.block-header__actions button,
.mat-column-actions button {
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  background-color: transparent; }
  .block-header__actions button .material-icons,
  .mat-column-actions button .material-icons {
    font-size: 28px;
    color: #5a5a5a; }
  .block-header__actions button.red:hover,
  .mat-column-actions button.red:hover {
    color: #F09100; }
    .block-header__actions button.red:hover .material-icons,
    .mat-column-actions button.red:hover .material-icons {
      color: #F09100; }
  .block-header__actions button.green .material-icons,
  .mat-column-actions button.green .material-icons {
    color: #50AF64; }
  .block-header__actions button.green:hover,
  .mat-column-actions button.green:hover {
    color: #50AF64; }
  .block-header__actions button.blue:hover,
  .mat-column-actions button.blue:hover {
    color: #369ef4; }
    .block-header__actions button.blue:hover .material-icons,
    .mat-column-actions button.blue:hover .material-icons {
      color: #369ef4; }
  .block-header__actions button.orange:hover,
  .mat-column-actions button.orange:hover {
    color: #F09100; }
    .block-header__actions button.orange:hover .material-icons,
    .mat-column-actions button.orange:hover .material-icons {
      color: #F09100; }

.table-push {
  padding-left: 200px; }

.mat-dialog-container .mat-dialog-actions {
  display: flex;
  justify-content: flex-end; }
  .mat-dialog-container .mat-dialog-actions button {
    margin-left: 10px; }
    .mat-dialog-container .mat-dialog-actions button:first-child {
      margin-left: 0; }

.cdk-overlay-pane > .mat-select-panel {
  max-width: 350px; }

.default-theme .mat-button,
.default-theme .mat-flat-button,
.default-theme .mat-icon-button,
.default-theme .mat-stroked-button,
.default-theme .mat-raised-button {
  border-radius: 0;
  line-height: 42px;
  min-width: 100px; }
  .default-theme .mat-button.icon-only, .default-theme .mat-button.mat-icon-button,
  .default-theme .mat-flat-button.icon-only,
  .default-theme .mat-flat-button.mat-icon-button,
  .default-theme .mat-icon-button.icon-only,
  .default-theme .mat-icon-button.mat-icon-button,
  .default-theme .mat-stroked-button.icon-only,
  .default-theme .mat-stroked-button.mat-icon-button,
  .default-theme .mat-raised-button.icon-only,
  .default-theme .mat-raised-button.mat-icon-button {
    min-width: 0; }
  .default-theme .mat-button.mat-primary,
  .default-theme .mat-flat-button.mat-primary,
  .default-theme .mat-icon-button.mat-primary,
  .default-theme .mat-stroked-button.mat-primary,
  .default-theme .mat-raised-button.mat-primary {
    background-color: #e4e4e4;
    color: #757575; }

.default-theme .mat-button-wrapper .material-icons {
  color: #F09100;
  margin-right: 3px; }
  .icon-only .default-theme .mat-button-wrapper .material-icons {
    margin-right: 0; }

.default-theme .material-icons.grey {
  color: #b4b4b4 !important; }
  .default-theme .material-icons.grey:hover {
    color: black !important; }

.default-theme .material-icons.black {
  color: black !important; }

.default-theme .icon-only .mat-button-wrapper .material-icons {
  margin-right: 0; }

.default-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: #50AF64; }

.default-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #fff; }

.default-theme .mat-option:hover:not(.mat-option-disabled),
.default-theme .mat-option:focus:not(.mat-option-disabled) {
  background: #e8e8e8; }

.default-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none; }

.default-theme .mat-form-field-flex {
  border: 1px solid #e8e8e8;
  padding: 0.5em;
  margin-top: 5px; }

.default-theme .mat-form-field-invalid .mat-form-field-flex {
  border-color: #F09100; }

.default-theme .mat-focused .mat-form-field-flex {
  border-color: #369ef4; }

.default-theme .mat-form-field-infix {
  border-top: 0; }

.default-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.default-theme .mat-form-field-appearance-legacy.mat-form-field-can-float
.mat-input-server:focus
+ .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-1.4em) scale(0.9) perspective(100px) translateZ(0.001px);
  background-color: #fff;
  width: auto; }

.default-theme .grid-header .mat-grid-tile .mat-figure {
  align-items: baseline; }

.default-theme .mat-row.pointer:hover {
  background-color: #f5f4f4; }

.default-theme .mat-row.pointer:focus {
  outline: none; }

.default-theme .mat-row.pointer:active {
  background-color: #e8e8e8; }

.default-theme .mat-form-field-label-wrapper {
  overflow: unset; }

.default-theme .mat-sidenav-container[_ngcontent-c0] {
  background-color: #fff; }

.default-theme .vt-filter .mat-spinner {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  pointer-events: none; }

.default-theme .block-filter {
  padding-left: 16px;
  padding-right: 16px; }
  .default-theme .block-filter .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0; }

.default-theme .footer-actions {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  margin-left: 200px; }
  .default-theme .footer-actions.wizard {
    margin-left: 40px; }
  .default-theme .footer-actions.summary {
    margin-left: 0px; }
  .default-theme .footer-actions button {
    margin-left: 15px; }
    .default-theme .footer-actions button:first-child {
      margin-left: 0; }

.default-theme .mat-column-actions .mat-flat-button {
  font-size: 14px;
  height: auto; }
  .default-theme .mat-column-actions .mat-flat-button .material-icons {
    font-size: 24px; }

.default-theme .mat-column-actions button {
  margin-left: 10px; }
  .default-theme .mat-column-actions button:first-child {
    margin-left: 0; }

.default-theme .form-group {
  display: flex;
  align-items: center;
  padding-bottom: 1.34375em; }
  .default-theme .form-group .vt-form-label {
    width: 200px;
    display: inline-block;
    flex: 0 0 auto; }
  .default-theme .form-group .mat-form-field {
    flex: 1 1 auto; }
  .default-theme .form-group .mat-form-field-flex {
    margin-top: 0; }
  .default-theme .form-group .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0; }
  .default-theme .form-group .mat-form-field-wrapper {
    padding-bottom: 0; }
  .default-theme .form-group--align-top {
    align-items: baseline; }

.default-theme .push-left {
  margin-left: auto; }

.default-theme .push-right {
  margin-right: auto; }

.default-theme .mat-column-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }
  .default-theme .mat-column-actions button {
    padding: 0;
    min-width: 0; }

.default-theme .table-big mat-row {
  padding: 15px 0; }

.default-theme .table-big button {
  font-size: 20px;
  padding: 2px 20px; }

.default-theme .table-big .mat-column-actions button {
  font-size: 20px;
  padding: 2px 20px; }

.default-theme .mat-form-field-hide-placeholder .mat-select-placeholder {
  color: #757575;
  -webkit-text-fill-color: unset; }

.default-theme .mat-step-header .mat-step-icon-selected {
  background-color: #50AF64; }

.default-theme .mat-step-header .mat-step-icon-state-done {
  background-color: #a8d7b2; }

.default-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #a8d7b2; }

.default-theme .isloading {
  padding-right: 42px; }
  .default-theme .isloading .mat-spinner {
    position: absolute;
    right: 10px;
    top: 6px; }
    .default-theme .isloading .mat-spinner circle {
      stroke: white; }

.default-theme .isloadingBlue {
  padding-right: 42px; }
  .default-theme .isloadingBlue .mat-spinner {
    display: inline-block;
    right: 10px; }
    .default-theme .isloadingBlue .mat-spinner circle {
      stroke: #369ef4; }

.cdk-overlay-pane {
  display: block; }

.default-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #757575; }

.mat-footer-row {
  font-weight: bold; }

body .mat-tab-body-wrapper {
  overflow: visible; }

body .mat-tab-body.mat-tab-body-active {
  overflow: visible; }
  body .mat-tab-body.mat-tab-body-active .mat-tab-body-content {
    overflow: visible; }

.cards-holder {
  padding: 20px; }
  .cards-holder .mat-card {
    margin-bottom: 30px; }

.mat-card-header.content-header .mat-card-title {
  font-size: 16px;
  margin-bottom: 0; }

.form-value-green {
  color: #50AF64 !important;
  font-weight: bold !important; }
  .form-value-green .mat-select-value {
    color: #50AF64 !important;
    font-weight: bold !important; }

.form-value-black {
  color: #333 !important; }
  .form-value-black .mat-select-value {
    color: #333 !important; }

.mat-checkbox.mat-checkbox-large .mat-checkbox-inner-container {
  height: 22px;
  width: 22px; }

@media screen {
  .print-checkbox-cell {
    display: none; } }

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    font-size: 12px; }
  .hidden-print {
    display: none !important; }
  .mat-drawer-container {
    overflow: visible !important; }
  .mat-drawer-container[fullscreen] {
    position: relative !important; }
  .mat-drawer-content {
    height: auto;
    overflow: visible !important; }
  .mat-card.block-header {
    page-break-after: avoid; }
    .mat-card.block-header .mat-card-title {
      margin-bottom: 0; }
  .mat-form-field-label-wrapper {
    display: none !important; }
  .default-theme .mat-form-field-flex {
    border: 0;
    padding: 0; }
  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0;
    font-size: 12px; }
  .mat-select-arrow {
    display: none; }
  .mat-select-value {
    max-width: none !important; }
  .mat-tab-label:not(.mat-tab-label-active) {
    display: none; }
  .mat-tab-label {
    height: auto !important;
    width: auto !important;
    min-width: auto !important;
    padding: 0 !important;
    opacity: 1 !important;
    color: #333 !important;
    font-size: 14px !important; }
  .mat-ink-bar {
    display: none; }
  .mat-tab-header {
    border-bottom: 0;
    padding-left: 16px; }
  .mat-cell,
  .mat-footer-cell {
    font-size: 12px;
    page-break-inside: avoid; }
  .mat-card-title {
    font-size: 18px; }
  .mat-header-cell {
    font-size: 13px; }
  mat-header-row {
    min-height: 35px !important;
    page-break-inside: avoid;
    display: table-header-group; }
  mat-footer-row,
  mat-row {
    min-height: 35px !important;
    page-break-inside: avoid; }
    mat-footer-row:nth-child(odd),
    mat-row:nth-child(odd) {
      background-color: rgba(0, 0, 0, 0.03); }
  .vt-tab-group {
    overflow: visible; }
  .mat-row,
  .mat-cell {
    page-break-inside: avoid; }
  .print-checkbox-cell {
    width: 40px;
    display: table-cell;
    flex: 0 0 auto;
    order: -1;
    padding-left: 16px;
    padding-right: 0 !important;
    align-items: center; }
    .print-checkbox-cell .print-checkbox {
      display: inline-flex;
      width: 20px;
      height: 20px;
      border: 1px solid #757575; }
  .margin-tab-group mat-tab-header {
    margin-bottom: 5px !important; }
  .mat-header-cell.mat-column-profileDescription,
  .mat-cell.mat-column-profileDescription {
    flex: 1 1 30%; }
  .cards-holder {
    padding: 0;
    margin-top: 15px; } }
