@media screen {
  .print-checkbox-cell {
    display: none;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    font-size: 12px;
  }

  .hidden-print {
    display: none !important;
  }

  .mat-drawer-container {
    overflow: visible !important;
  }

  .mat-drawer-container[fullscreen] {
    position: relative !important;
  }

  .mat-drawer-content {
    height: auto;
    overflow: visible !important;
  }

  .mat-card.block-header {
    page-break-after: avoid;
    .mat-card-title {
      margin-bottom: 0;
    }
  }

  .mat-form-field-label-wrapper {
    display: none !important;
  }

  .default-theme {
    .mat-form-field-flex {
      border: 0;
      padding: 0;
    }
  }

  .mat-form-field-appearance-legacy {
    .mat-form-field-infix {
      padding: 0;
      font-size: 12px;
    }
  }

  .mat-select-arrow {
    display: none;
  }

  .mat-select-value {
    max-width: none !important;
  }

  .mat-tab-label:not(.mat-tab-label-active) {
    display: none;
  }

  .mat-tab-label {
    height: auto !important;
    width: auto !important;
    min-width: auto !important;
    padding: 0 !important;
    opacity: 1 !important;
    color: $black !important;
    font-size: 14px !important;
  }

  .mat-ink-bar {
    display: none;
  }

  .mat-tab-header {
    border-bottom: 0;
    padding-left: 16px;
  }

  .mat-cell,
  .mat-footer-cell {
    font-size: 12px;
    page-break-inside: avoid;
  }
  .mat-card-title {
    font-size: 18px;
  }
  .mat-header-cell {
    font-size: 13px;
  }
  mat-header-row {
    min-height: 35px !important;
    page-break-inside: avoid;
    display: table-header-group;
  }

  mat-footer-row,
  mat-row {
    min-height: 35px !important;
    page-break-inside: avoid;
    &:nth-child(odd) {
      background-color: $light-grey;
    }
  }

  .vt-tab-group {
    overflow: visible;
  }

  .mat-row,
  .mat-cell {
    page-break-inside: avoid;
  }

  .print-checkbox-cell {
    width: 40px;
    display: table-cell;
    flex: 0 0 auto;
    order: -1;
    padding-left: 16px;
    padding-right: 0 !important;
    align-items: center;

    .print-checkbox {
      display: inline-flex;
      width: 20px;
      height: 20px;
      border: 1px solid $grey;
    }
  }

  .margin-tab-group {
    mat-tab-header {
      margin-bottom: 5px !important;
    }
  }

  .mat-header-cell,
  .mat-cell {
    &.mat-column-profileDescription {
      flex: 1 1 30%;
    }
  }

  .cards-holder {
    padding: 0;
    margin-top: 15px;
  }
}
