.flex-row {
    display: flex;
}

@for $i from 1 through 12 {
    .flex-#{$i} {
      flex: 0 0 calc((#{$i}/12)*100%);
    }
  }

  .fill-space {
    flex: 1 1 auto;
    width: 100%;
  }
  
  .flex-column {
      flex-direction: column;
  }